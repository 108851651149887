var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"DN Claim"}},[_c('vs-button',{staticClass:"mb-2",on:{"click":_vm.handleOpenForm}},[_vm._v("Create")]),_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.detail ? _vm.detailShow + 'md:w-1/3 w-full mb-base' : '',
        _vm.detailHide,
      ]},[_c('vs-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('vs-tab',{attrs:{"label":"Open"}},[_c('open',{key:_vm.componentKey})],1),_c('vs-tab',{attrs:{"label":"Pending","focus":true}},[_c('div',{staticClass:"tab-text"},[_c('pending')],1)]),_c('vs-tab',{attrs:{"label":"Rejected"}},[_c('div',{staticClass:"tab-text"},[_c('reject')],1)]),_c('vs-tab',{attrs:{"label":"Approved"}},[_c('div',{staticClass:"tab-text"},[_c('approved',{attrs:{"debitNoteCode":_vm.debitNoteCode},on:{"reset-dn-code-params":_vm.handleResetDnCode}})],1)]),_c('vs-tab',{attrs:{"label":"Applied"}},[_c('div',{staticClass:"tab-text"},[_c('applied')],1)]),_c('vs-tab',{attrs:{"label":"Paid"}},[_c('div',{staticClass:"tab-text"},[_c('paid')],1)]),_c('vs-tab',{attrs:{"label":"Waiting Approval Reversal"}},[_c('div',{staticClass:"tab-text"},[_c('waiting-revers')],1)]),_c('vs-tab',{attrs:{"label":"Reversed"}},[_c('div',{staticClass:"tab-text"},[_c('reversed')],1)])],1)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
          _vm.detail ? _vm.detailShow + 'md:w-2/3 w-full mb-base' : '',
          _vm.detailHide,
        ]},[_c('vs-row',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{staticClass:"ml-auto",attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}},[_vm._v("Close")])],1),_c('vs-col',{staticClass:"vertical-divider",attrs:{"vs-offset":"0","vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"1"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}})],1),_c('form-create',{on:{"close":_vm.handleClose,"copy":_vm.copyPrompt}})],1)],1):_vm._e()]),_c('vs-popup',{ref:"popup",attrs:{"title":"Debit Note Code","button-close-hidden":"","active":_vm.activePrompt,"closeOnBackdrop":false},on:{"update:active":function($event){_vm.activePrompt=$event}}},[_c('div',{staticClass:"w-full flex flex-col"},[_c('div',{staticClass:"flex gap-3 items-center w-full"},[_c('vs-input',{staticClass:"w-full",attrs:{"readonly":""},model:{value:(_vm.docNumber),callback:function ($$v) {_vm.docNumber=$$v},expression:"docNumber"}}),_c('vs-button',{attrs:{"iconPack":"feather","icon":"icon-file","type":"border"},on:{"click":_vm.copyToClipboard}},[_vm._v("Copy")])],1),_c('div',{staticClass:"mt-8"},[_c('vs-button',{attrs:{"color":"danger"},on:{"click":_vm.closePrompt}},[_vm._v("Close")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }